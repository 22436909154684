import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  constructor() { }

  latitude = 0;
  longitude =0;
  mapType = 'roadmap';

  ngOnInit() {

    this.latitude = 38.5745931;
    this.longitude = -90.3934053;
  }

}
