import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { RouterModule } from '@angular/router';   

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SignUpComponent } from './user/sign-up/sign-up.component';
import { SignInComponent } from './user/sign-in/sign-in.component';
import { HomeComponent } from './home/home.component';
import { FormsModule } from '@angular/forms';
import { UserService } from './Shared/user.service';
import { SpeechRecognitionServiceService } from './speech-recognition-service.service';
import { HttpClientModule } from '@angular/common/http';
import { UserComponent } from './user/user.component';
import { appRoutes } from './routes';
import { ProductdetailComponent } from './productdetail/productdetail.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { ShoppingcartComponent } from './shoppingcart/shoppingcart.component';
import { NgxImageZoomModule } from 'ngx-image-zoom';
import { WishlistComponent } from './wishlist/wishlist.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CategoryComponent } from './category/category.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { FooterComponent } from './footer/footer.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import {
  SpeechRecognitionModule,
} from '@kamiazya/ngx-speech-recognition';


@NgModule({
  declarations: [
    AppComponent,
    SignUpComponent,
    SignInComponent,
    UserComponent,
    HomeComponent,
    ProductdetailComponent,
    HeaderComponent,
    ShoppingcartComponent,
    WishlistComponent,
    DashboardComponent,
    CategoryComponent,
    CheckoutComponent,
    FooterComponent,
    AboutComponent,
    ContactComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    NgxImageZoomModule.forRoot(),
    // AgmCoreModule.forRoot({
    //   apiKey: 'AIzaSyBKTubnywg4QcYcBViqABbYrKM8l0mY8kI'
    //   /* apiKey is required, unless you are a 
    //   premium customer, in which case you can 
    //   use clientId 
    //   */
    // }),

    RouterModule.forRoot(
      appRoutes,
      { enableTracing: true } 
    ),
    SpeechRecognitionModule.withConfig({
      lang: 'en-US',
      interimResults: true,
      maxAlternatives: 10,
    }),
   
  ],
  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { }
