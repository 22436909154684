import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/Shared/user.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
declare const M;

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {

  shoppingCart = [];
  shoppingcartcopy = [];
  username = localStorage.getItem("UserName");
  Address = "";
  firstName = "";
  lastName = "";
  email = "";
  phoneNumber = "";
  fullname = "";
  cardnumber = "1449 4428 2587 2158";
  expirymonth = "12";
  expiryyear = "25";
  cvc = "789";



  constructor(private userService: UserService, private router: Router) { }

  ngOnInit() {

    var elems = document.querySelectorAll('.tabs');
    var instances = M.Tabs.init(elems, {});

    var elems1 = document.querySelectorAll('select');
    var instances1 = M.FormSelect.init(elems1, {});

    this.shoppingCart = JSON.parse(localStorage.getItem("shoppingCarts"));
    this.getcustomerinfo();
  }

  GetProductImage(Path) {
    var img = "https://admin.shivamitcs.in"
    if (Path != "") {
      Path = Path.split(",")[0];

      return img + "/ProductImages/Large/" + Path;
    }
    return "../img/no-image.png";
  }

  removeExistingItem(key) {
    if (localStorage.getItem(key) === null)
      return false;
    localStorage.removeItem(key);
    return true;
  }

  RemoveCartGlobal(Product) {

    debugger;
    var _localCartItems = localStorage.getItem("shoppingCarts");
    if (_localCartItems != null && _localCartItems != undefined) {
      var allItems = JSON.parse(_localCartItems);
      var allNewItems = [];
      for (var i = 0; i < allItems.length; i++) {

        if (allItems[i].ProductId != Product.ProductId) {
          allNewItems.push(allItems[i]);
        }
      }
      this.removeExistingItem('shoppingCarts');
      localStorage.setItem("shoppingCarts", JSON.stringify(allNewItems));

      this.ngOnInit()

    }
    else {
      alert("No Item remaining")
    }
  }


  getcustomerinfo() {

    this.userService.getcustomerdetail(this.username).subscribe((data: any) => {

      debugger;

      this.Address = data.data[0].address[0].address;
      this.firstName = data.data[0].firstname;
      this.lastName = data.data[0].lastname;
      this.email = data.data[0].email;
      this.phoneNumber = data.data[0].phone;
      this.fullname = this.firstName + " " + this.lastName

    },
      (err: HttpErrorResponse) => {

      }
    )

  }

  getTotal() {
    var total = 0;
    for (var i = 0; i < this.shoppingCart.length; i++) {
      var product = this.shoppingCart[i];
      total += (product.productprice * product.Quantity);
    }
    return total;
  }

  getTotals() {
    var total = 0;
    for (var i = 0; i < this.shoppingCart.length; i++) {
      var product = this.shoppingCart[i];
      total += (product.productprice * product.Quantity);
    }
    return total + 120;
  }

  submitOrder() {
    Swal.fire('Thank You', 'Your order has been placed, you will be find these product with in 24 hrs in your hand', 'success')
  }



}
