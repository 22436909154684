import { Component, OnInit, HostListener, ElementRef } from '@angular/core';
import { UserService } from 'src/app/Shared/user.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { NgxImageZoomModule } from 'ngx-image-zoom';

import { Collapsible } from "materialize-css";
import { Dropdown } from "materialize-css";
import { HeaderComponent } from '../header/header.component';

import { SpeechRecognitionServiceService } from 'src/app/speech-recognition-service.service';
declare const M;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {




  myusername = localStorage.getItem("loginuser");

  constructor(private userService: UserService, private router: Router, private speechRecognitionService: SpeechRecognitionServiceService) { }
  showSearchButton: boolean;
  speechData: string;

  products;
  categories;
  GlobalImage;
  _IsLazyLoadingUnderProgress;
  _TotalRecordsCurrent;
  itemsPerPage = 21;
  LoadMore;
  Min = "";
  Max = "";
  categoriesarraySelect = [];
  categoriesobj = "";
  searchText = null;
  showloader;
  username;
  token;
  AllProductsColumns = [];
  thumb = ""

  Listview = false;
  isShow: boolean;
  topPosToStartShowing = 1000;

  @HostListener('window:scroll')
  checkScroll() {

    // window의 scroll top
    // Both window.pageYOffset and document.documentElement.scrollTop returns the same result in all the cases. window.pageYOffset is not supported below IE 9.

    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

    console.log('[scroll]', scrollPosition);

    if (scrollPosition >= this.topPosToStartShowing) {
      this.isShow = true;
    } else {
      this.isShow = false;
    }
  }

  // TODO: Cross browsing
  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }



  ngOnInit() {
    this.getproduct(this.itemsPerPage, this.Min, this.Max, this.categoriesobj, this.searchText)
    this.GetCategories();
    this.showloader = true;

    var elems = document.querySelectorAll('.collapsible');
    var instances = M.Collapsible.init(elems, {});

    var elems5 = document.querySelectorAll('.sidenavright');
    var instances5 = M.Sidenav.init(elems5, { edge: 'right' });

    this.showSearchButton = true;
    this.speechData = "";

  }

  ngOnDestroy() {
    //  this.speechRecognitionService.speechRecognition.OnDestroy);
    // this.speechRecognitionService.DestroySpeechObject();
  }
  activateSpeechSearchMovie(): void {
    debugger;

    this.showSearchButton = false;

    this.speechRecognitionService.record()
      .subscribe(
        //listener
        (value) => {
          this.speechData = value;
          this.showSearchButton = true;
          console.log(value);
        },
        //errror
        (err) => {
          console.log(err);
          if (err.error == "no-speech") {
            console.log("--restatring service--");
            this.activateSpeechSearchMovie();
          }
        },
        //completion
        () => {
          this.showSearchButton = true;
          console.log("--complete--");

          // this.activateSpeechSearchMovie();
        });
  }



  getproduct(itemsperpage, Min, Max, categoriesobj, searchtext) {
    this._IsLazyLoadingUnderProgress = 1;
    this.userService.getallProducts(itemsperpage, Min, Max, categoriesobj, searchtext).subscribe((data: any) => {
      debugger;
      this.products = data.aaData;
      this._TotalRecordsCurrent = data.iTotalDisplayRecords;
      console.log(this.products);
      this._IsLazyLoadingUnderProgress = 0;
      this.LoadMore = false;
      this.showloader = false;
      var colStartn = 0;
      for (var i = 0; i < data.aoColumns.length; i++) {
        if (data.aoColumns[i] == 'CateogryID') {
          if (colStartn == 0) {
            colStartn = colStartn + 1;
            continue;
          }
        }
        else {
          if (colStartn > 0) {
            this.AllProductsColumns.push({ ColName: data.aoColumns[i], colIndex: i });
          } else {
            continue;
          }
        }
      }
      console.log("Columns");
      console.log(this.AllProductsColumns);
    },
      (err: HttpErrorResponse) => {
        debugger;
        alert("Error123");
        this._IsLazyLoadingUnderProgress = 0;
      }
    )
  }




  GetCategories() {
    this.userService.getallCategories().subscribe((data: any) => {
      this.categories = data;
      debugger;
    },
      (err: HttpErrorResponse) => {
        debugger;
        alert("Error123");
      }
    )
  }

  GetProductImage(Path) {
    this.GlobalImage = "https://admin.shivamitcs.in"
    if (Path != "") {
      Path = Path.split(",")[0];

      return this.GlobalImage + "/ProductImages/Large/" + Path;
    }
    return "../img/no-image.png";
  }

  LoadMoreProducts() {
    if (this._IsLazyLoadingUnderProgress === 0 && this._TotalRecordsCurrent != 0) {
      if (this.itemsPerPage < this._TotalRecordsCurrent) {
        this.LoadMore = true;
        this.showloader = false;
        this._IsLazyLoadingUnderProgress = 1;
        this.itemsPerPage = this.itemsPerPage + 20;
        this.getproduct(this.itemsPerPage, this.Min, this.Max, this.categoriesobj, this.searchText)
      }

    }
  }

  pricefilter(Min, Max) {

    this.showloader = true;

    this.getproduct(this.itemsPerPage, Min, Max, this.categoriesobj, this.searchText)
  }

  AddCatID(CategoryId, CategoryName, event) {
    this.showloader = true;

    if (event.target.checked) {
      this.categoriesarraySelect.push(CategoryId);
    }
    else {
      var index = this.categoriesarraySelect.indexOf(CategoryId);
      if (index > -1) {
        this.categoriesarraySelect.splice(index, 1);
      }
    }

    this.categoriesobj = this.categoriesarraySelect.join();
    this.getproduct(this.itemsPerPage, this.Min, this.Max, this.categoriesobj, this.searchText)

  }

  SetProduct(data) {
    localStorage.setItem("ProductDetail", JSON.stringify(data));
    localStorage.setItem("ProductAttribute", JSON.stringify(this.AllProductsColumns));
    this.router.navigate([`${"productdetail"}`]);
  }

  searckproducts(searchtext) {

    this.showloader = true;
    this.getproduct(this.itemsPerPage, this.Min, this.Max, this.categoriesobj, searchtext)
  }

  showfilter() {
    document.getElementById("filtersection").style.display = "block";
    document.getElementById("allproducts").style.display = "none";


  }

  hidefilter() {
    document.getElementById("filtersection").style.display = "none";
    document.getElementById("allproducts").style.display = "block";
  }

}
