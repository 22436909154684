import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';     // Add this
import { UserComponent } from './user/user.component';
import { SignUpComponent } from './user/sign-up/sign-up.component';
import { SignInComponent } from './user/sign-in/sign-in.component';
import { ProductdetailComponent } from './productdetail/productdetail.component';
import { ShoppingcartComponent } from './shoppingcart/shoppingcart.component';
import { WishlistComponent } from './wishlist/wishlist.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CategoryComponent } from './category/category.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';



export const appRoutes: Routes = [

  {
    path: 'signup',
    component: SignUpComponent
  },
  {
    path: 'login',
    component: SignInComponent
  },
  {
    path: 'dashboard',
    component: DashboardComponent
  },

  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'productdetail',
    component: ProductdetailComponent
  },
  {
    path: 'shoppingcart',
    component: ShoppingcartComponent
  },
  {
    path: 'checkout',
    component: CheckoutComponent
  },

  {
    path: 'wishlist',
    component: WishlistComponent
  },
  {
    path: 'category',
    component: CategoryComponent
  },
  {
    path: 'contact',
    component: ContactComponent
  },
  {
    path: 'about',
    component: AboutComponent
  },

  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  }
];