
import { Component, OnInit, OnDestroy} from '@angular/core';
import { SpeechRecognitionServiceService } from 'src/app/speech-recognition-service.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit, OnDestroy  {

  showSearchButton: boolean;
  speechData: string;
 
  constructor(private speechRecognitionService: SpeechRecognitionServiceService) {
    this.showSearchButton = true;
    this.speechData = "";
}

ngOnInit() {
    console.log("hello")
}

ngOnDestroy() {
  //  this.speechRecognitionService.speechRecognition.OnDestroy);
 // this.speechRecognitionService.DestroySpeechObject();
}
activateSpeechSearchMovie(): void {
debugger;

  this.showSearchButton = false;

  this.speechRecognitionService.record()
      .subscribe(
      //listener
      (value) => {
          this.speechData = value;
          this.showSearchButton = true;
          console.log(value);
      },
      //errror
      (err) => {
          console.log(err);
          if (err.error == "no-speech") {
              console.log("--restatring service--");
              this.activateSpeechSearchMovie();
          }
      },
      //completion
      () => {
          this.showSearchButton = true;
          console.log("--complete--");
          
         // this.activateSpeechSearchMovie();
      });
}

}
