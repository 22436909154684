import { Component, OnInit, Input } from '@angular/core';

import { Dropdown } from "materialize-css";

declare const M;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {


  constructor() { }


  username;
  token;
  public CartCounter = 0;
  cartdata;

  public goodbye: string;


  checkuseravailability() {
    this.ngOnInit();
  }


  ngOnInit() {

    this.username = localStorage.getItem("loginuser");

    this.token = localStorage.getItem("token");
    this.cartdata = JSON.parse(localStorage.getItem("shoppingCarts"));

    if (this.CartCounter != null && this.CartCounter != undefined && this.cartdata != null) {
      this.CartCounter = this.cartdata.length;
    }

    var elems1 = document.querySelectorAll('.dropdown-trigger');
    var instances1 = M.Dropdown.init(elems1, {});

    var elems = document.querySelectorAll('.sidenav');
    var instances = M.Sidenav.init(elems, {});

  }

  public setDatafromchild(data) {
    this.goodbye = data;
  }

  public getcounterfromchild(data) {
    debugger;
    this.CartCounter = data;
  }

  logout() {
    localStorage.setItem('loginuser', '');
    this.ngOnInit()
  }


}
