import { Component, OnInit, Input } from '@angular/core';
import { UserService } from 'src/app/Shared/user.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import Swal from 'sweetalert2'

declare var cordova: any;

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {


  constructor(private userService: UserService, private router: Router) { }
  UserName = "Test";
  Password;




  ngOnInit() {


  }



  token;
  showloader = false;


  OnSubmit(UserName, Password) {

    this.showloader = true;
    this.userService.userAuthenitication(UserName, Password).subscribe((data: any) => {

      localStorage.setItem('UserName', data.userName);
      localStorage.setItem('loginuser', data.userName);


      localStorage.setItem('token', data.access_token);
      this.showloader = false;
      this.router.navigate([`${"category"}`]);
      Swal.fire(localStorage.getItem("loginuser"), 'You are successfully loged in!', 'success')

    },
      (err: HttpErrorResponse) => {
        debugger;
        this.showloader = false;
        Swal.fire('OOops..', 'Invalid Credentials !!', 'error')

      }

    )
  }


  getproduct() {


  }


  scanvalue() {

    var scanner = cordova.plugins.barcodeScanner;

    scanner.scan(function (result) {

      alert(result.text);
      this.UserName = result.text;
      this.Password = result.text;

      setTimeout(function () {
        this.OnSubmit(result.text, result.text);
      }, 10000)



    }, function (error) {
      console.log("Scanning failed: ", error);
    });
  }

}
