import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UserService } from 'src/app/Shared/user.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { JsonPipe } from '@angular/common';
import Swal from 'sweetalert2';

declare const bootbox;



@Component({
    selector: 'app-wishlist',
    templateUrl: './wishlist.component.html',
    styleUrls: ['./wishlist.component.scss']
})
export class WishlistComponent implements OnInit {

    constructor(private userService: UserService, private router: Router) { }

    wishlistitem = [];
    username = localStorage.getItem("UserName");
    AllCartItems = [];
    public CartProductsCounter = 0;
    shoppingCart = [];
    image = [];
    showloader = true;

    ngOnInit() {

        debugger;
        this.mywishlist();

    }


    GetProductImage(Path) {
        var img = "https://admin.shivamitcs.in"
        if (Path != "") {
            Path = Path.split(",")[0];

            return img + "/ProductImages/Large/" + Path;
        }
        return "../img/no-image.png";
    }

    RemoveFromwishList(id) {

        //   bootbox.alert("Hello world!")

        if (confirm('Are you sure you want to delete Items?')) {

            this.showloader = true;
            debugger;
            this.userService.deletewishlist(id).subscribe((data: any) => {
                Swal.fire("", 'Deleted', 'success')
                this.ngOnInit()

                debugger;

            },
                (err: HttpErrorResponse) => {
                    this.showloader = false;
                    Swal.fire("Operation failed", 'Something went wrong...No data deleted', 'error');


                }
            )
        }

    }

    mywishlist() {

        this.userService.loadmywishlist(this.username).subscribe((data: any) => {

            this.wishlistitem = data;
            console.log(this.wishlistitem);
            this.showloader = false;
            debugger;

        },
            (err: HttpErrorResponse) => {
                debugger;
                alert("Error123");
                this.showloader = false;
            }
        )
    }

    AddToCartGlobal(ProductID, product, cartQuantity, cols) {
        debugger;

        this.image.push({ 'imagename': "", 'imagepath': product.image });

        this.shoppingCart = JSON.parse(localStorage.getItem("shoppingCarts"));

        if (this.shoppingCart != null) {
            for (i = 0; i < this.shoppingCart.length; i++) {
                if (this.shoppingCart[i].ProductName == product.productName) {

                    this.shoppingCart[i].Quantity = this.shoppingCart[i].Quantity + 1

                    localStorage.setItem("shoppingCarts", JSON.stringify(this.shoppingCart));
                    Swal.fire(product.productName, 'Successfully Added', 'success')
                    return 0;
                }
            }
        }

        this.AllCartItems = localStorage.getItem("shoppingCarts") === null ? [] : JSON.parse(localStorage.getItem("shoppingCarts"));
        var item = this.AllCartItems.filter(function (item) {
            debugger;


            if (product.ProductAttributes == undefined) {
                if (item.ProductId === product[4]) {
                    item.Quantity = item.Quantity + 1;
                }
            }
            else {
                if (item.ProductId === product.ProductVersionId) {
                    item.Quantity = item.Quantity + 1;
                }
            }


            return product.ProductAttributes == undefined ? item.ProductId === product[4] : item.ProductId === product.ProductId;
        })[0];
        if (item == undefined) {
            this.CartProductsCounter++;
            var des = "";
            var k = 18;
            if (product.ProductAttributes == undefined) {
                for (var i = 0; i < 3; i++) {

                    if (product[k] == null || product[k] == "-") {
                        k = k + 1;
                    }
                    else {
                        des = des + "<strong>" + cols[i].ColName + "</strong> : " + "(" + product[k].toUpperCase() + ") ";
                        k = k + 1;
                    }
                }
                cartQuantity = cartQuantity == undefined || cartQuantity == null ? 1 : cartQuantity;
                this.AllCartItems.push({ ProductId: ProductID, Quantity: cartQuantity, Image: this.image, ProductName: product.productName, productDescription: product.productDescription, productprice: product.productPrice });
            } else {
                for (var i = 0; i < product.ProductAttributes.length; i++) {

                    if (product.ProductAttributes[i].attrvalue == null || product.ProductAttributes[i].attrvalue == "-") {
                        continue;
                    }
                    else {
                        des = des + "<strong>" + product.ProductAttributes[i].attrName + "</strong> : " + "(" + product.ProductAttributes[i].attrvalue + ") ";
                        k = k + 1;
                    }
                }
                cartQuantity = cartQuantity == undefined || cartQuantity == null ? 1 : cartQuantity;
                this.AllCartItems.push({ ProductId: ProductID, Quantity: cartQuantity, Image: this.image, ProductName: product.productName, productDescription: product.productDescription, productprice: product.productPrice });
            }

        }
        debugger;
        if (product[9] == undefined) {

            product[9] = product.ProductName;
        }
        Swal.fire(product.productName, 'Successfully Added', 'success')
        localStorage.setItem("shoppingCarts", JSON.stringify(this.AllCartItems));


        var data = JSON.parse(localStorage.getItem("shoppingCarts"));

        console.log(data);






    }



}
