import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UserService } from 'src/app/Shared/user.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { JsonPipe } from '@angular/common';
import Swal from 'sweetalert2'


@Component({
    selector: 'app-productdetail',
    templateUrl: './productdetail.component.html',
    styleUrls: ['./productdetail.component.scss']
})
export class ProductdetailComponent implements OnInit {


    @Input() public state: string;

    @Output() event: EventEmitter<string> = new EventEmitter();

    public goodbye = "Goodbye friends";

    localProductDetail;
    ProductName;
    GlobalImage;
    similarproduct;
    allProductDetailNewWithAttr = [];
    SelecteditemDetail = [];

    AllCartItems = [];
    public CartProductsCounter = 0;
    shoppingCart = [];

    showloader = true;



    constructor(private userService: UserService, private router: Router) {


    }



    ngOnInit() {

        this.localProductDetail = JSON.parse(localStorage.getItem("ProductDetail"));

        console.log(this.localProductDetail);
        this.shoppingCart = JSON.parse(localStorage.getItem("shoppingCarts"));

        this.Similarproduct()


    }


    sendtoparent() {
        this.event.emit(this.goodbye);
    }

    GetProductImage(Path) {
        this.GlobalImage = "https://admin.shivamitcs.in"
        if (Path != "") {
            Path = Path.split(",")[0];

            return this.GlobalImage + "/ProductImages/Large/" + Path;
        }
        return "../img/no-image.png";
    }

    isAttrExist(arry, stringtocheck) {
        var retunv = false;
        for (var i = 0; i < arry.length; i++) {
            if (arry[i].Value == stringtocheck) {
                retunv = true;
                break;
            }
        }
        return retunv;
    }

    Similarproduct() {
        var itemNew = JSON.parse(localStorage.getItem("ProductDetail"));
        var itemattribute = JSON.parse(localStorage.getItem("ProductAttribute"));
        this.userService.getsimilarproducts(itemNew[4]).subscribe((data: any) => {


            debugger;

            this.similarproduct = data.allAttributes;
            if (this.similarproduct.length > 0) {
                this.allProductDetailNewWithAttr.push({
                    ProductName: this.similarproduct[0].productName,
                    ProductVersionId: this.similarproduct[0].id,
                    SuppplierName: this.similarproduct[0].supplierName,
                    description: this.similarproduct[0].productDesc,
                    productprice: this.similarproduct[0].productprice,
                    ProductAttributes: this.similarproduct[0].productAttrubutes,
                    ProductImages: this.similarproduct[0].productImage,
                });
            }

            console.log("this.allProductDetailNewWithAttr")
            console.log(this.allProductDetailNewWithAttr)

            this.showloader = false;


        },
            (err: HttpErrorResponse) => {
                debugger;
                alert("Error123");
                this.showloader = false;
            }
        )
    }


    checkforProductDetail() {
        var isnot = true;
        for (var i = 0; i < this.SelecteditemDetail.length; i++) {
            if (this.SelecteditemDetail[i].AttrValue == '') {
                var itemNew = JSON.parse(localStorage.getItem("ProductDetail"));
                var itemattribute = JSON.parse(localStorage.getItem("ProductAttribute"));
                for (var k = 0; k < itemattribute.length; k++) {

                    if (itemattribute[k].ColName == this.SelecteditemDetail[i].AttrName) {
                        this.SelecteditemDetail[i].AttrValue = itemNew[itemattribute[k].colIndex].toUpperCase();

                    }
                }
            }
        }
        return isnot;
    }


    GetOtherDetails(item) {
        var allValues = item == undefined ? [] : item.split("#");
        for (var i = 0; i < this.SelecteditemDetail.length; i++) {
            if (allValues[0] == this.SelecteditemDetail[i].AttrName) {
                this.SelecteditemDetail[i].AttrValue = allValues[1];
            }
        }

        if (this.checkforProductDetail() == false) {
            alert("please select all attribute values");
        } else {

            for (var i = 0; i < this.similarproduct.length; i++) {
                var ProductCounter = 0;
                for (var k = 0; k < this.similarproduct[i].productAttrubutes.length; k++) {

                    for (var count = 0; count < this.SelecteditemDetail.length; count++) {
                        if (this.similarproduct[i].productAttrubutes[k].attrName == this.SelecteditemDetail[count].AttrName && this.similarproduct[i].productAttrubutes[k].attrvalue.toUpperCase() == this.SelecteditemDetail[count].AttrValue) {
                            ProductCounter = ProductCounter + 1;

                        }
                    }

                }
            }
        }
    }

    AddToCartGlobal(ProductID, product, ID, cartQuantity, cols) {
        debugger;

        this.shoppingCart = JSON.parse(localStorage.getItem("shoppingCarts"));

        if (this.shoppingCart != null) {
            for (i = 0; i < this.shoppingCart.length; i++) {
                if (this.shoppingCart[i].ProductName == product.ProductName) {

                    this.shoppingCart[i].Quantity = this.shoppingCart[i].Quantity + 1

                    localStorage.setItem("shoppingCarts", JSON.stringify(this.shoppingCart));
                    Swal.fire(product.ProductName, 'Successfully Added', 'success')
                    return 0;
                }
            }
        }

        this.AllCartItems = localStorage.getItem("shoppingCarts") === null ? [] : JSON.parse(localStorage.getItem("shoppingCarts"));
        debugger;
        var item = this.AllCartItems.filter(function (item) {
            debugger;







            if (product.ProductAttributes == undefined) {
                if (item.ProductId === product[4]) {
                    item.Quantity = item.Quantity + 1;
                }
            }
            else {
                if (item.ProductId === product.ProductVersionId) {
                    item.Quantity = item.Quantity + 1;
                }
            }


            return product.ProductAttributes == undefined ? item.ProductId === product[4] : item.ProductId === product.ProductId;
        })[0];
        if (item == undefined) {
            this.CartProductsCounter++;
            var des = "";
            var k = 18;
            if (product.ProductAttributes == undefined) {
                for (var i = 0; i < 3; i++) {

                    if (product[k] == null || product[k] == "-") {
                        k = k + 1;
                    }
                    else {
                        des = des + "<strong>" + cols[i].ColName + "</strong> : " + "(" + product[k].toUpperCase() + ") ";
                        k = k + 1;
                    }
                }
                cartQuantity = cartQuantity == undefined || cartQuantity == null ? 1 : cartQuantity;
                this.AllCartItems.push({ ProductId: ProductID, Quantity: cartQuantity, Image: product.ProductImages, ProductName: product.ProductName, productDescription: product.description, productprice: product.productprice });
            } else {
                for (var i = 0; i < product.ProductAttributes.length; i++) {

                    if (product.ProductAttributes[i].attrvalue == null || product.ProductAttributes[i].attrvalue == "-") {
                        continue;
                    }
                    else {
                        des = des + "<strong>" + product.ProductAttributes[i].attrName + "</strong> : " + "(" + product.ProductAttributes[i].attrvalue + ") ";
                        k = k + 1;
                    }
                }
                cartQuantity = cartQuantity == undefined || cartQuantity == null ? 1 : cartQuantity;
                this.AllCartItems.push({ ProductId: ProductID, Quantity: cartQuantity, Image: product.ProductImages, ProductName: product.ProductName, productDescription: product.description, productprice: product.productprice });
            }

        }
        debugger;

        Swal.fire(product.ProductName, 'Successfully Added', 'success')
        localStorage.setItem("shoppingCarts", JSON.stringify(this.AllCartItems));


        var data = JSON.parse(localStorage.getItem("shoppingCarts"));

        console.log(data);



        this.event.emit(data.length);


    }

    AddTowishlist(Id, Product) {
        var wishListmodel = { ProductId: Id, Productattrid: Id, CustomerId: -1, UserID: localStorage.getItem("UserName") }
        this.userService.getmywishlist(wishListmodel).subscribe((data: any) => {

            Swal.fire(Product.ProductName, 'Added to wishlist', 'success')
            debugger;
        },
            (err: HttpErrorResponse) => {
                debugger;
                alert("Error123");
            }
        )

    }
}
