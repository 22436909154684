import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';



import { User } from './user.model';




@Injectable({
  providedIn: 'root'
})
export class UserService {

  readonly rooturl = "https://api.shivamitconsultancy.com/"



  constructor(private http: HttpClient) { }

  registerUser(username, password, fname, lname, email) {

    debugger;
    const body: User = {
      confirmPassword: password,
      email: email,
      firstname: fname,
      lastname: lname,
      password: password,
      phone: "9874563210",
      userName: username
    }

    return this.http.post(this.rooturl + '/api/User/Register', body)

  }

  userAuthenitication(UserName, Password) {

    var data = "grant_type=password&username=" + UserName + "&password=" + Password;

    let reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });

    return this.http.post(this.rooturl + '/token', data, { headers: reqHeader })

  }

  getallProducts(Itemsperpage, Min, Max, categoriesobj, searchtext) {
    debugger;
    var _model = { displayLength: Itemsperpage, displayStart: 0, searchText: searchtext, filtertext: "", Categories: categoriesobj, lowprice: Min, highprice: Max, isFeatured: "0", ProductId: "" };
    let reqHeader = new HttpHeaders({ dataType: 'json', });
    return this.http.post(this.rooturl + 'api/Product/GetAllproduct', _model, { headers: reqHeader })
  }


  getallCategories() {
    debugger;
    let reqHeader = new HttpHeaders({ dataType: 'json', });
    return this.http.get(this.rooturl + 'api/Categories/GetCategories', { headers: reqHeader })
  }

  getmywishlist(_model) {

    let reqHeader = new HttpHeaders({ dataType: 'json', });
    return this.http.post(this.rooturl + 'api/CustomerWishlist/PostWishList', _model, { headers: reqHeader })
  }

  loadmywishlist(name) {

    let params = new HttpParams().set("UserName", name)

    let reqHeader = new HttpHeaders({ dataType: 'json', });
    return this.http.get(this.rooturl + 'api/CustomerWishlist/GetWishLists', { headers: reqHeader, params: params })
  }

  getcustomerdetail(name) {

    let params = new HttpParams().set("UserName", name)

    let reqHeader = new HttpHeaders({ dataType: 'json', });
    return this.http.get(this.rooturl + 'api/CustomerWishlist/GetCustomerinfo', { headers: reqHeader, params: params })
  }



  deletewishlist(id) {
    let reqHeader = new HttpHeaders({ dataType: 'json', });
    debugger;
    return this.http.post(this.rooturl + 'api/WishlistDelete/DeleteWishList?id=' + id, { headers: reqHeader })
    debugger;
  }



  getsimilarproducts(productID) {
    debugger;

    let params = new HttpParams().set("productID", productID)
    let reqHeader = new HttpHeaders({ dataType: 'json', });
    return this.http.get(this.rooturl + 'api/Product/Similarproduct', { headers: reqHeader, params: params })
  }





}
