import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2'
import { Router } from '@angular/router';

@Component({
  selector: 'app-shoppingcart',
  templateUrl: './shoppingcart.component.html',
  styleUrls: ['./shoppingcart.component.scss']
})
export class ShoppingcartComponent implements OnInit {


  shoppingCart = [];
  shoppingcartcopy = [];

  constructor(private router: Router) { }

  ngOnInit() {

    this.shoppingCart = JSON.parse(localStorage.getItem("shoppingCarts"));


    for (var i = 0; i < this.shoppingCart.length; i++) {


    }

    console.log(this.shoppingCart);

  }

  GetProductImage(Path) {
    var img = "https://admin.shivamitcs.in"
    if (Path != "") {
      Path = Path.split(",")[0];

      return img + "/ProductImages/Large/" + Path;
    }
    return "../img/no-image.png";
  }

  removeExistingItem(key) {
    if (localStorage.getItem(key) === null)
      return false;
    localStorage.removeItem(key);
    return true;
  }

  RemoveCartGlobal(Product) {

    debugger;
    var _localCartItems = localStorage.getItem("shoppingCarts");
    if (_localCartItems != null && _localCartItems != undefined) {
      var allItems = JSON.parse(_localCartItems);
      var allNewItems = [];
      for (var i = 0; i < allItems.length; i++) {

        if (allItems[i].ProductId != Product.ProductId) {
          allNewItems.push(allItems[i]);
        }
      }
      this.removeExistingItem('shoppingCarts');
      localStorage.setItem("shoppingCarts", JSON.stringify(allNewItems));
      window.location.reload();
      this.ngOnInit()

    }
    else {
      alert("No Item remaining")
    }
  }

  getTotal() {
    var total = 0;
    for (var i = 0; i < this.shoppingCart.length; i++) {
      var product = this.shoppingCart[i];
      total += (product.productprice * product.Quantity);
    }
    return total;
  }


  checkout() {
    this.router.navigate(['/checkout'])
    //Swal.fire("Coming Soon", '', 'warning')
  }

}
