import { Component, OnInit } from '@angular/core';
import { User } from '../../Shared/user.model';
import { NgForm } from '@angular/forms'
import { UserService } from '../../Shared/user.service';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {
  user: User

  userobject: { Username: "", password: "", firstname: "Radha", lastname: "", email: "" }

  constructor(private userService: UserService) { }

  ngOnInit() {

    this.userobject.firstname = "Radha";

  }

  OnSubmit(Username, password, firstname, lastname, email) {


    this.userService.registerUser(Username, password, firstname, lastname, email)
      .subscribe((data: any) => {
        alert("Success");
      })
  }

}
