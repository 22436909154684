import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { UserService } from 'src/app/Shared/user.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { JsonPipe } from '@angular/common';
import Swal from 'sweetalert2';
declare const M;


@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit, AfterViewInit {

  categories;
  allcatimage = [];


  constructor(private userService: UserService, private router: Router) {

  }


  ngOnInit() {
    this.GetCategories();
    var elems = document.querySelectorAll('.carousel-slider');
    var instances = M.Carousel.init(elems, {});
  }

  ngAfterViewInit() {


    setTimeout(() => {

      var elems1 = document.querySelectorAll('.carousel');
      var instances1 = M.Carousel.init(elems1, { fullWidth: true });
    }, 500);


  }

  GetCategories() {
    this.userService.getallCategories().subscribe((data: any) => {


      this.categories = data;



      for (var i = 0; i < this.categories.length; i++) {
        for (var j = 0; j < this.categories[i].categories1.length; i++) {
          this.allcatimage.push({ 'Name': this.categories[i].categories1[j].categoryName, 'Image': this.categories[i].categories1[j].categoryImage })
        }
      }
      console.log("Mapped array");
      console.log(this.allcatimage);

    },
      (err: HttpErrorResponse) => {
        debugger;
        alert("Error123");
      }
    )
  }

  GetcatBgImage(Path) {
    if ($.trim(Path) != "") {
      return "https://admin.shivamitcs.in/CategoryImage/" + Path;
    }
    return "https://www.indiantradebird.com/content/images/thumb/NoPicAvailable.png";
  }


}
